.top-nav.scrolled {
    padding: 13px 0px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.08%);

    .logo {
        display: none;
    }

    .logo-scrolled {
        display: block;
        height: 54px;

    }

    .nav-inner {
        ul {
            li.current_page_item {
                border-bottom: 2px solid #0F86AC;
            }
            li {
                a {
                    color: #000 !important;
                }
            }
        }
    }

    .nav-btn {
        color: #FFFFFF;
        background-color: #0F86AC;
    }
}

.alt-nav.top-nav {
    .nav-btn {

        color: #FFFFFF;
        background-color: #0F86AC;

        &:hover {
            background-color: #fff;

            &:hover {
                color: #0F86AC;
            }
        }
    }
}

.alt-nav.top-nav.scrolled {

    .nav-btn {
        &:hover {
            color: #FFFFFF;
            background-color: #02021B;
        }
    }
}


.top-nav {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 40px 0px;
    transition: all 0.1 ease-in-out;
    z-index: 99;

    @media (max-width: 991px) {
        padding: 13px 0px;
        background-color: #fff;
        box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.08%);

        .nav-inner {
            display: none !important;
        }

        .mobile-menu {
            display: block !important;
        }
    }

    .mobile-menu {
        display: none;
    }



    .logo {
        display: block;
        height: 68px;
    }

    .logo-scrolled {
        display: none;
    }

    .nav-btn {
        width: 160px;
        background-color: #fff;
        border-radius: 24px;
        color: #02021B;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        font-weight: 500;
        text-align: center;
        display: inline-block;
        padding: 17px 0px;
        text-decoration: none;
        text-transform: uppercase;
        transition: background-color 0.1s ease-in-out;

        &:hover {
            background-color: #02021B;
            color: #fff;
        }
    }

    .menu-primary-nav-container {
        display: inline-block;
    }

    .nav-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        ul {
            padding-left: 0px;
            margin-bottom: 0px;

            li.current_page_item {
                border-bottom: 2px solid #fff;
            }

            li {
                list-style: none;
                display: inline-block;
                margin-right: 42px;
border-bottom: 2px solid transparent;
                a {
                    color: #FFFFFF;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 16px;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-weight: 500;
                    transition: all 0.1s ease-in-out;
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}


@keyframes bugfix { from {padding:0;} to {padding:0;}}
@-webkit-keyframes bugfix { from {padding:0;} to {padding:0;}}

#overlay-button {
    position: absolute;
    right: -11px;
    top: 3px;
    padding: 26px 11px;
    z-index: 5;
    cursor: pointer;
    user-select: none;
    @media (max-width: 767px) {
        top: 2px;
    }
    span {
        height: 2px;
        width: 35px;
        border-radius: 2px;
        background-color: #000;
        position: relative;
        display: block;
        transition: all .2s ease-in-out;
        
        @media (max-width: 767px) {
            width: 26px;
        }
        &:before {
            top: -10px;
            visibility: visible;
            @media (max-width: 767px) {
                top: -8px;
            }
        }
        &:after {
            top: 10px;
            @media (max-width: 767px) {
                top: 8px;
            }
        }
        &:before, &:after {
            height: 2px;
            width: 35px;
            border-radius: 2px;
            background-color: #000;
            position: absolute;
            content: "";
            transition: all .2s ease-in-out;
            @media (max-width: 767px) {
                width: 26px;
            }
        }
    }
    
}


#overlay {
    height: calc(100vh);
    

    .menu-item-has-children {

		.slideopen {
			&:after {
				transform: rotate(180deg) scaleX(-1);
			}
		}
		
		
		&>a {
			position: relative;
			
			&:after {
				content: "";
				background-image: url('/wp-content/themes/coquet-cottages/src/images/caret-down.svg');
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				height: 30px;
				width: 30px;
				position: absolute;
				right: 5%;
				top: -2px;
				transition: all .2s ease-in-out;
			}
		}
		
		&>a {
			display: block;
		}
	}
	
	ul.sub-menu {
		padding-left: 0px;
		margin-top: 20px;
        list-style: none;
		li {
			padding-bottom: 10px;
			a {
				font-size: 14px;
			}
		}
	}
    width: 100vw;
    background: #02021B;
    z-index: 2;
    transform: translateX(100vw);
    position: fixed;
    transition: all .2s ease-in-out;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: stretch;
    &.active {
        transform: none;
    }
    ul.sub-menu {
        display: none;
    }
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        height: 100vh;
        padding-left: 0;
        list-style-type: none;
        li {
            padding: 30px 0px;
            &:last-of-type {
                border-bottom: none;
            }
            a {
                
                display: block;
                color: #FFFFFF;
                font-family: Interstate;
                font-size: 24px;
                letter-spacing: 0;
                text-decoration: none;
                line-height: 26px;
                height: 100%;
                width: 100%;
                &:hover {
                    color: white;
                }
            }
        }
    }
    .menu {
        margin-left: 0;
    }
    
}


.mobile-menu {
 padding: 5px 0px;
 position: relative;
 .mobile-logo {
    height: 50px;
 }
    input[type=checkbox] {
        display: none;
    }
    
    input[type=checkbox]:checked ~ #overlay {
        transform: none;
    }
    
    input[type=checkbox]:checked ~ #overlay-button {

        span {
            background-color: #fff;
            &:before {
                background-color: #fff;
            }
            &:after {
                background-color: #fff;
            }
        }
    }
    
    input[type=checkbox]:checked ~ #overlay-button {
        &:hover span, span{
            background: transparent;
        }
        span {
            &:before {
                transform: rotate(45deg) translate(7px, 7px);
                opacity: 1;
                
                @media(max-width: 767px) {
                    transform: rotate(45deg) translate(4px, 5px);
                }
            }
            &:after {
                transform: rotate(-45deg) translate(7px, -7px);
            }
        }
    }
}


#overlay {
    visibility: hidden;

    #mobileMenuContact {
        margin-top: 20px;
    }
}

.mobile-menu-open {
    visibility: visible !important;
}