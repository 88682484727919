.latest-news {
    padding: 100px 0px;
    overflow: hidden;

    @media (max-width: 1200px) {
        padding: 50px 0px;
    }
    @media (max-width: 767px) {
        padding: 30px 0px 0px 0px;
    }
    .latest-news-header {
        justify-content: space-between;
        margin-bottom: 70px;
        @media (max-width: 576px) {
            flex-direction: column;
            justify-content: start;
            align-items: start !important;
            margin-bottom: 00px;
        }
    }

    .gx-5 {
        --bs-gutter-x: 7rem;
    }

    h2 {
        display: inline-block;
        @media (max-width: 576px) {
            margin-right: 30px;
            color: #FFFFFF;
            font-size: 38px;
            font-weight: 200;
            letter-spacing: -2.57px;
            line-height: 48px;
            margin-bottom: 30px;
        }
    }

    p {
        color: #DDDDE4;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: -0.5px;
        line-height: 32px;
        margin-bottom: 23px;
    }

    .btn-blue {
        display: inline-block;
        @media (max-width: 576px) {
            margin-bottom: 40px;
        }
    }

    .news-item {
        @media (max-width: 991px) {
            margin-bottom: 40px;
        }
        span {
            display: block;
            color: #0F86AC;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            font-weight: 500;
            margin-bottom: 15px;
        }

        h3 {
            color: #FFFFFF;
            font-size: 26px;
            font-weight: 200;
            letter-spacing: -1px;
            line-height: 38px;
            margin-bottom: 15px;
        }

        .arrow-link {
            height: 44px;
            width: 44px;
            background-color: #0F86AC;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
transition: all 0.1s ease-in-out;
            &:hover {
                background-color: #fff;

                svg {
                    g {
                        fill:  #0F86AC
                    }
                    
                }
            }
        }
    }
}