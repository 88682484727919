.news-single-header {
    padding-top: 210px;
    overflow: hidden;

    @media (max-width: 576px) {
        padding-top: 150px;
    }

    .date {
        display: block;
        margin-bottom: 25px;
        color: #11B0E2;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19px;
    }

    h1 {
        color: #FFFFFF;
        font-size: 48px;
        font-weight: 200;
        letter-spacing: -2.57px;
        line-height: 58px;
        margin-bottom: 50px;

        @media (max-width: 576px) {
            margin-bottom: 20px;
            color: #FFFFFF;
            font-size: 38px;
            font-weight: 200;
            letter-spacing: -2.57px;
            line-height: 48px;
        }
    }



    .news-single-image {
        display: block;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 54%;
        margin-bottom: 50px;

    }
}

.news-single-content {
    padding-bottom: 50px;
    color: #fff !important;

    @media (max-width:576px) {
        padding-bottom: 0px;
    }

    p {

        a {
            color: #FFFFFF !important;

            &:hover {
                color: #FFFFFF !important;
            }
        }
    }

    .share-icons {
        margin-bottom: 60px;
        margin-top: 40px;

        a {
            text-decoration: none;
            display: inline-block;
            margin-right: 15px;
            margin-bottom: 5px;
        }

        span {
            color: #FFFFFF;
            font-size: 16px;
            letter-spacing: -0.4px;
            line-height: 19px;
            font-weight: 500;
            text-align: center;
            margin-right: 15px;
        }
    }
}