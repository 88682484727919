.footer {
    margin-top: 100px;
    background-color: #fff;

    .footer-top {
        padding-top: 140px;
        padding-bottom: 150px;

        @media (max-width: 991px) {
            padding: 50px 0px;
        }



        .footer-logo {
            margin-bottom: 30px;
        }

        .gform_validation_error {
            input[type='email'] {
                border-bottom: 1px solid #c02b0a !important;
            }
        }

        .gform_wrapper {
            margin-bottom: 40px;

            .gfield_validation_message,
            .gform_wrapper.gravity-theme .validation_message {
                padding: 0px !important;
                background: none !important;
                border: none !important;
            }



            .gform_validation_errors {
                display: none;
            }

            form {
                position: relative;

                .gform_footer {
                    position: absolute;
                    right: 0;
                    top: -20px;
                }
            }

            .ginput_container {

                input {
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    border: none;
                    border-bottom: 1px solid #000;
                    outline: none;
                    padding: 5px 0px;
                    color: #6D6D6D;
                    font-size: 20px;
                    font-weight: 300;
                    letter-spacing: -0.77px;
                    line-height: 38px;
                }
            }
        }

        .contact-info {

            span {
                display: block;
                margin-bottom: 24px;
                color: #000000;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 16px;

                a {
                    color: #0F86AC;
                    text-decoration: none;
                    transition: all 0.1s ease-in-out;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            .social-icons {
                a {
                    margin-top: 22px;
                    display: inline-block;
                    margin-right: 20px;
                    text-decoration: none;
                    transition: all 0.1s ease-in-out;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }



        .footer-nav {

            ul {
                padding-left: 0px;
                margin-bottom: 0px;

                li.current_page_item {
                    a {
                        border-bottom: 2px solid #000;
                    }

                }

                li {
                    list-style: none;
                    margin-bottom: 24px;

                    a {
                        color: #000000;
                        text-decoration: none;
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 16px;
                        padding-bottom: 5px;
                        transition: all 0.1s ease-in-out;

                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
            }
        }

        .form-intro {
            color: #0F86AC;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: -0.62px;
            line-height: 24px;
        }

        h5 {
            color: #0F86AC;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: -0.69px;
            line-height: 38px;
            font-weight: 400;
            margin-bottom: 15px;
        }
    }


    .footer-bottom {
        display: flex;
        justify-content: space-between;
        padding: 22px 0px;

        @media (max-width: 576px) {
            flex-direction: column;
        }


        .cr {
            margin-right: 30px;
        }

        .ne6 {
            a {
                color: #000;
                text-decoration: none;
                transition: all 0.1s ease-in-out;
                font-weight: 500;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        span {
            color: #6D6D6D;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 38px;
            display: inline-block;
        }

        ul {
            padding-left: 0px;
            margin-bottom: 0px;
            display: inline-block;

            li {
                list-style: none;
                display: inline-block;
                margin-right: 25px;

                a {
                    color: #6D6D6D;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0;
                    line-height: 38px;
                    text-decoration: none;
                    transition: all 0.1s ease-in-out;

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }

        }
    }
}

.footer-border {
    border-top: 1px solid #D8D8D8 !important;
}

body img.gform_ajax_spinner {
    display: none !important;
}