.wwd-intro {
    padding: 100px 0px;
    @media (max-width: 1200px) {
        padding: 50px 0px;
    }
    @media (max-width: 767px) {
        padding: 30px 0px;
    }
    h2 {
        text-align: center;
        margin-bottom: 21px;
        @media (max-width: 576px) {
            margin-right: 30px;
            color: #FFFFFF;
            font-size: 38px;
            font-weight: 200;
            letter-spacing: -2.57px;
            line-height: 48px;
        }
    }

    p {
        text-align: center;
        color: #FFFFFF;
        font-size: 26px;
        font-weight: 200;
        letter-spacing: -1px;
        line-height: 38px;
        @media (max-width: 576px) {
            color: #DDDDE4;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: -0.5px;
            line-height: 32px;
        }
    }
}

.wwd-content {
    padding: 40px 0px 100px 0px;
    overflow: hidden;
    @media (max-width: 1200px) {
        padding: 50px 0px;
    }
    .gx-5 {
        --bs-gutter-x: 8.5rem;

        .col-12:nth-child(even) {
            margin-top: 200px;
            @media (max-width: 991px) {
                margin-top: 0px;
            }
        }

        @media (max-width: 991px) {
            .col-12 {
                &:last-of-type {
                    .wwd-item {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
    .wwd-item {
        // margin-bottom: 140px;
        @media (max-width: 991px) {
            margin-bottom: 100px;
        }
        @media (max-width: 576px) {
            
            margin-bottom: 60px;
        }
        .wwd-image {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            display: block;
            padding-top: 90%;
            margin-bottom: 40px;
        }

        .icon {
            display: block;
            margin-bottom: 40px;
            height: 50px;
        }

        h3 {
            color: #FFFFFF;
            font-family: Interstate;
            font-size: 36px;
            font-weight: 200;
            letter-spacing: -1.93px;
            line-height: 44px;
            margin-bottom: 24px;
        }
    }
}