.services-block {
    padding: 240px 0px 100px 0px;

    @media (max-width: 1200px) {
        padding: 50px 0px;
    }

    @media (max-width: 767px) {
        padding: 30px 0px;
    }

    .gx-5 {
        --bs-gutter-x: 3rem;
    }

    .services-block-content {
        padding-left: 67px;
        margin-right: 90px;

        @media (max-width: 1200px) {
            margin-bottom: 150px;
        }

        @media (max-width: 991px) {
            padding-left: 0px;
            margin-right: 0px;
        }

        @media (max-width: 767px) {
            margin-bottom: 50px;
        }

        span {
            color: #11B0E2;
            font-family: Interstate;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            display: block;
            margin-bottom: 37px;
            text-transform: uppercase;
            font-weight: 500;

            @media (max-width: 576px) {
                margin-bottom: 20px;
            }
        }

        h2 {
            color: #FFFFFF;
            font-size: 48px;
            font-weight: 200;
            letter-spacing: -2.57px;
            line-height: 58px;
            margin-bottom: 45px;

            @media (max-width: 576px) {
                margin-bottom: 20px;
                color: #FFFFFF;
                font-size: 38px;
                font-weight: 200;
                letter-spacing: -2.57px;
                line-height: 48px;
            }
        }

        p {
            color: #DDDDE4;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: -0.5px;
            line-height: 32px;
            margin-bottom: 20px;
            padding-right: 17%;

            @media (max-width: 767px) {
                padding-right: 0%;
                margin-right: 0px;
                margin-bottom: 30px;
            }
        }
        .btn-blue {
            margin-top: 40px;
        }
    }

    .service-col {
        align-items: center;
        display: flex;
        flex-direction: column;

        @media (max-width:767px) {
            flex-direction: row;
            justify-content: center;
            gap: 30px;
        }
        @media (max-width:576px) {
            flex-direction: column;
            justify-content: center;
            gap: 0px;
        }
        &:first-of-type {
            margin-top: -50px;

            @media (max-width: 1200px) {
                margin-top: -30px;
            }

            @media (max-width:767px) {
                margin-top: 0px;
            }
        }

        &:last-of-type {
            margin-top: -240px;

            @media (max-width: 1200px) {
                margin-top: -180px;
            }

            @media (max-width:767px) {
                margin-top: 0px;
            }
        }
    }

    .service {
        background-color: #29293D;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 250px;
        width: 100%;
        max-width: 210px;
        margin-bottom: 50px;

        @media (max-width: 1400px) {
            height: 210px;
        }

        @media (max-width: 1200px) {
            height: 250px;
        }

        img {
            margin-bottom: 62px;
        }

        span {
            color: #FFFFFF;
            font-size: 16px;
            letter-spacing: -0.4px;
            line-height: 19px;
            text-align: center;
            display: block;
            font-weight: 500;

        }
    }
}