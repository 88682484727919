.home-hero {
  height: 100vh;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: end;
  min-height: 750px;

  @media (max-width: 576px) {
    min-height: 600px;
  }

  &:after {
    content: "";
    background: linear-gradient(180deg, rgba(2, 2, 27, 0) 0%, #02021B 100%);
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 665px;

  }
  .container {
    display: flex;
    align-self: flex-end;
  }

  .hero-content {
    padding-left: 57px;
    position: relative;
    z-index: 1;
    margin-bottom: 60px;

    @media (max-width: 767px) {
      padding-left: 0px;
    }
  }

  h1 {
    color: #FFFFFF;
    font-size: 92px;
    font-weight: 200;
    letter-spacing: -5.17px;
    line-height: 110px;
    margin-bottom: 14px;

    @media (max-width: 991px) {
      color: #FFFFFF;
      font-size: 62px;
      font-weight: 200;
      letter-spacing: -3.17px;
      line-height: 68px;
      margin-bottom: 14px;
    }

    @media (max-width: 576px) {
      color: #FFFFFF;
      font-size: 42px;
      font-weight: 200;
      letter-spacing: -2.17px;
      line-height: 48px;
      margin-bottom: 14px;
    }
  }

  p {
    color: #FFFFFF;
    font-size: 26px;
    font-weight: 200;
    letter-spacing: -1px;
    line-height: 38px;
    margin-bottom: 50px;

    @media (max-width: 576px) {
      color: #FFFFFF;
      font-size: 21px;
      font-weight: 200;
      letter-spacing: -1px;
      line-height: 33px;
      margin-bottom: 29px;
    }
  }

  .hero-button {
    display: block;
    width: 180px;
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #0F86AC;
    padding: 16px 0px;
    border-radius: 24px;
    transition: all 0.1s ease-in-out;

    &:hover {
      color: #0F86AC;
      background-color: #FFFFFF;
    }
  }

  .scroll-arrow {
    position: absolute;
    right: -35%;
    bottom: 0;

    @media (max-width: 991px) {
      right: 10%;
    }
  }
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
  animation-iteration-count: 2;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}