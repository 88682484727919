@font-face {
    font-family: DanmarkURWDem;
    src: url("/wp-content/themes/pernille-lind-studio/src/fonts/DanmarkURW-Dem.ttf") format("truetype");
}
@font-face {
    font-family: DanmarkURWLig;
    src: url("/wp-content/themes/pernille-lind-studio/src/fonts/DanmarkURW-Lig.ttf") format("truetype");
}

@font-face {
    font-family: aAutoSignature;
    src: url("/wp-content/themes/pernille-lind-studio/src/fonts/aAutoSignature.ttf") format("truetype");
}