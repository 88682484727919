.our-aircraft-content {
    padding: 150px 0px 50px 0px;

    @media (max-width: 767px) {
        padding: 50px 0px 50px 0px;
    }
    .table-container {
        // @media (max-width: 576px) {
        //     padding-right: 0px;
        // }
    }

    .col-12 {
        overflow: scroll;
        overflow-y: hidden;
        padding-bottom: 10px;

        /* WebKit and Chromiums */
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: #02021B;
        }

        &::-webkit-scrollbar-thumb {
            background: #C9C9D5;
            border-radius: 5px;
        }
    }

    div#tab-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @media (max-width: 991px) {
            width: fit-content;
        }

    }

    div#tab-wrapper>ul#tab-buttons {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        border-bottom: 1px solid #C4C4CB;
        // @media (max-width: 991px) {
        //     border-bottom: none;
        // }
    }

    ul#tab-buttons>li {
        margin: 0 -1px 0 0;
        padding: 16px 20px;
        background-repeat: repeat;
        color: #ADADAD;
        font-family: Interstate;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 32px;
        min-width: 250px;
        text-align: center;

        // @media (max-width: 991px) {
        //     border-bottom: 1px solid #C4C4CB;
        // }

    }

    ul#tab-buttons>li:hover {
        cursor: pointer;
    }

    ul#tab-buttons>li.active {
        padding-bottom: 0;
        background-color: #0F86AC;
        color: #fff;
    }

    div#tab-wrapper>div#tab-content {
        display: block;
        margin-top: 23px;
        width: 100%;
        height: 100%;
    }

    div>#tab-content>.tab {
        visibility: hidden;
        display: flex;
        flex-direction: column;
        opacity: 0;
        transition: all 500ms;
        width: 100%;
        display: none;
    }

    div>#tab-content>.tab.active {
        visibility: visible;
        opacity: 1;
        transition: all 500ms;
        display: block;
    }

    div>#tab-content>.tab.active>.row:first-child {
        margin-top: 0;
    }


    table {
        width: 100%;
        table-layout: auto;
        overflow-x: auto;
        scrollbar-color: red;

        th {
            color: #11B0E2;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            font-weight: 500;
            padding: 10px 15px;
        }

        td {
            color: #C9C9D5;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 28px;
            padding: 24px 15px;
            width: 110px;
            white-space: nowrap;
        }

        tr {
            border-bottom: 1px solid #67676F;
        }
    }

}