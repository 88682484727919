.news-listing {
    padding-top: 210px;

    @media (max-width: 576px) {
        padding-top: 150px;
    }

    overflow: hidden;

    h1 {
        color: #FFFFFF;
        font-size: 70px;
        font-weight: 200;
        letter-spacing: -3px;
        line-height: 84px;

        @media (max-width: 991px) {
            color: #FFFFFF;
            font-size: 62px;
            font-weight: 200;
            letter-spacing: -3.17px;
            line-height: 68px;
            margin-bottom: 14px;
        }

        @media (max-width: 576px) {
            color: #FFFFFF;
            font-size: 42px;
            font-weight: 200;
            letter-spacing: -2.17px;
            line-height: 48px;
            margin-bottom: 14px;
        }
    }

    p {
        color: #FFFFFF;
        font-size: 26px;
        font-weight: 200;
        letter-spacing: -1px;
        line-height: 38px;
    }

    .gx-5 {
        --bs-gutter-x: 4.5rem;
    }

    .col-lg-4 {

        &:last-of-type {
            .news-item {
                margin-bottom: 0px
            }
        }
    }

    .news-grid {
        padding: 100px 0px;

        @media (max-width: 576px) {
            padding: 50px 0px;
        }
    }

    .news-item {
        margin-bottom: 70px;

        @media (max-width: 576px) {
            margin-bottom: 40px;
        }

        .news-item-image {
            display: block;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            padding-top: 65%;
            margin-bottom: 30px;
        }

        span {
            display: block;
            color: #11B0E2;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            font-weight: 500;
            margin-bottom: 15px;
        }

        h3 {
            color: #FFFFFF;
            font-size: 26px;
            font-weight: 200;
            letter-spacing: -1px;
            line-height: 38px;
            margin-bottom: 15px;
        }

        .arrow-link {
            height: 44px;
            width: 44px;
            background-color: #0F86AC;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: all 0.1s ease-in-out;

            &:hover {
                background-color: #fff;

                svg {
                    g {
                        fill: #0F86AC
                    }

                }
            }
        }
    }
}

.navigation.pagination {

    .next,
    .prev {
        color: #FFFFFF;
        font-family: Interstate;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19px;
        font-weight: 500;
        text-decoration: none;
    }

    .prev {
        margin-right: 10px;

        @media (max-width: 576px) {
            margin-right: 5px;
            content: "<";
        }
    }

    .next {
        margin-left: 10px;

        @media (max-width: 576px) {
            margin-left: 5px;
            content: ">";
        }
    }

    .page-numbers {

        &:not(.next):not(.prev) {
            display: inline-flex;
            height: 36px;
            width: 36px;
            color: #fff;
            background-color: #02021B;
            align-items: center;
            justify-content: center;
            margin: 0px 10px;
            text-decoration: none;
            border-radius: 50%;

            @media (max-width: 576px) {
                margin: 0px 5px;
            }
        }

    }

    .page-numbers.current {
        background-color: #11B0E2 !important;
    }
}