.plain-text-template {
    padding-top: 210px;
    @media (max-width: 576px) {
        padding-top: 150px;
    }
color: #FFFFFF !important;
    h1 {
        color: #FFFFFF;
        font-size: 48px;
        font-weight: 200;
        letter-spacing: -2.57px;
        line-height: 58px;
        margin-bottom: 50px;
        @media (max-width: 991px) {
            color: #FFFFFF;
            font-size: 62px;
            font-weight: 200;
            letter-spacing: -3.17px;
            line-height: 68px;
            margin-bottom: 14px;
          }
      
          @media (max-width: 576px) {
            color: #FFFFFF;
            font-size: 42px;
            font-weight: 200;
            letter-spacing: -2.17px;
            line-height: 48px;
            margin-bottom: 14px;
          }
    }

    h2 {
        @media (max-width: 576px) {
            margin-right: 30px;
            color: #FFFFFF;
            font-size: 38px;
            font-weight: 200;
            letter-spacing: -2.57px;
            line-height: 48px;
            margin-bottom: 30px;
        }
    }
    p {

        a {
            color: #FFFFFF !important;
            &:hover {
                color: #FFFFFF !important;
            }
        }
    }

    h3 {
        color: #FFFFFF;
        font-size: 26px;
        font-weight: 200;
        letter-spacing: -1px;
        line-height: 38px;
    }
}