.three-column-content {
    padding: 100px 0px;
overflow: hidden;
    @media (max-width: 1200px) {
        padding: 50px 0px;
    }

    @media (max-width: 767px) {
        padding: 30px 0px;
    }

    .gx-5 {
        --bs-gutter-x: 4rem;
    }

    h2 {
        text-align: center;
margin-bottom: 90px;
        @media (max-width: 576px) {
            color: #FFFFFF;
            font-size: 38px;
            font-weight: 200;
            letter-spacing: -2.57px;
            line-height: 48px;
            margin-bottom: 40px;
        }
    }

    .tcc-item {
        @media (max-width: 991px) {
            margin-bottom: 50px;
        }
    }

    .tcc-image {
        padding-top: 85%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 30px;
    }
    h4 {
        color: #FFFFFF;
        font-family: Interstate;
        font-size: 26px;
        font-weight: 200;
        letter-spacing: -1px;
        line-height: 38px;
        margin-bottom: 24px;
    }
}