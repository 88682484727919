.panel-content-full {
    margin: 100px 0px;

    @media (max-width: 1200px) {
        margin: 50px 0px;
    }

    @media (max-width: 767px) {
        margin: 30px 0px;
    }

    .panel {
        height: 680px;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;

        .panel-content {
            padding: 150px 90px;
            position: absolute;
            right: 0;
            top: 0;
            left: 58%;
            bottom: 0;
            background-color: rgba(41, 41, 61, 0.86);
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 1200px) {
                padding: 40px 50px;
            }

            @media (max-width: 991px) {
                left: 0;
            }

            span {
                color: #11B0E2;
                font-family: Interstate;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 16px;
                display: block;
                margin-bottom: 37px;
                text-transform: uppercase;
                font-weight: 500;
                @media (max-width: 576px) {
                    margin-bottom: 20px;
                }
            }

            h2 {
                color: #FFFFFF;
                font-size: 48px;
                font-weight: 200;
                letter-spacing: -2.57px;
                line-height: 58px;
                margin-bottom: 41px;
                @media (max-width: 576px) {
                    margin-bottom: 20px;
                    color: #FFFFFF;
                    font-size: 38px;
                    font-weight: 200;
                    letter-spacing: -2.57px;
                    line-height: 48px;
                }
            }

            p {
                color: #DDDDE4;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: -0.5px;
                line-height: 32px;
                margin-bottom: 51px;
                padding-right: 17%;
                @media (max-width: 767px) {
                    padding-right: 0%;
                    margin-right: 0px;
                    margin-bottom: 30px;
                }
            }
        }
    }
}