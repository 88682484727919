
.content-image {
padding: 100px 0px;
overflow: hidden;

@media (max-width: 1200px) {
    padding: 50px 0px;
}
@media (max-width: 767px) {
    padding: 30px 0px;
}

.gx-5 {
    --bs-gutter-x: 6rem;
    @media (max-width: 1400px) {
        --bs-gutter-x: 5rem;
        display: flex;
        align-items: center;
    }

}
    span {
        color: #11B0E2;
        font-family: Interstate;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        display: block;
        margin-bottom: 37px;
        text-transform: uppercase;
        font-weight: 500;
        @media (max-width: 576px) {
            margin-bottom: 20px;
        }
    }

    h2 {
        color: #FFFFFF;
        font-size: 48px;
        font-weight: 200;
        letter-spacing: -2.57px;
        line-height: 58px;
        margin-bottom: 45px;
        @media (max-width: 576px) {
            margin-bottom: 20px;
            color: #FFFFFF;
            font-size: 38px;
            font-weight: 200;
            letter-spacing: -2.57px;
            line-height: 48px;
        }
    }

    p {
        color: #DDDDE4;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: -0.5px;
        line-height: 32px;
        margin-bottom: 15px;
        padding-right: 17%;
        @media (max-width: 576px) {
            padding-right: 0;
            margin-bottom: 30px;
        }
    }
    .content-image-content {
        padding-right: 73px;
        @media (max-width: 1200px) {
            padding-right: 0px;
        }

    }

    .btn-blue {
        margin-top: 45px;
    }
    .content-image-image {
        padding-top: 98%;
        margin-top: 150px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @media (max-width: 1400px) {
            margin-top: 0px;
        }
        @media (max-width: 991px) {
            margin-bottom: 40px;
        }
    }
}