* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    scrollbar-color: blue #02021B;
    scrollbar-width: thin;
  }

body {
    background-color: #02021B;
    color: #02021B;
    font-family: "interstate", sans-serif;
}
@media (min-width: 1400px) {
    .container {
        max-width: 1380px;
    }
}
@media (min-width: 1500px) {
    .container {
        max-width: 1460px;
    }
}


.container {
    @media (max-width: 576px) {
        padding: 0px 20px;
    }
}

h2 {
    color: #FFFFFF;
    font-size: 48px;
    font-weight: 200;
    letter-spacing: -2.57px;
    line-height: 58px;
}
h3 {
    color: #FFFFFF;
    font-size: 26px;
    font-weight: 200;
    letter-spacing: -1px;
    line-height: 38px;
}
p {
    color: #DDDDE4;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.5px;
    line-height: 32px;
}

.btn-blue {
    display: block;
    width: 180px;
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #0F86AC;
    padding: 16px 0px;
    border-radius: 24px;
    transition: all 0.1s ease-in-out;
    &:hover {
        color: #0F86AC;
        background-color: #FFFFFF;
    }
}